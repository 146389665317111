@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  color-scheme: dark;
}

body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
  color: #f1f8e9;
}

.main {
  overflow-y: auto;
  width: 100%;
  background: radial-gradient(circle at 30% 30%, #1a0b3f88, transparent 70%),
              radial-gradient(circle at 70% 25%, #c7cdff5b, transparent 50%),
              radial-gradient(circle at 50% 50%, #a0c5fd5b, transparent 60%);
}

.main-inner {
  overflow-y: auto;
  width: 100%;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.scale-center{
  animation: scale-center 300ms ease 0s 1 normal forwards;
}

.slide-left{
  animation: slide-left 300ms ease 0s 1 normal forwards;
}

.bounce-in{
  animation: bounce-in 1s ease 0s 1 normal forwards;
}

@keyframes scale-center {
	0% {
    opacity: 0;
		transform: scale(0.5);
	}

	100% {
    opacity: 1;
		transform: scale(1);
	}
}

@keyframes slide-left {
	0% {
		opacity: 0;
		transform: translateX(-250px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes bounce-in {
	0% {
		animation-timing-function: ease-in;
		opacity: 0;
		transform: translateY(-250px);
	}

	38% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateY(0);
	}

	55% {
		animation-timing-function: ease-in;
		transform: translateY(-65px);
	}

	72% {
		animation-timing-function: ease-out;
		transform: translateY(0);
	}

	81% {
		animation-timing-function: ease-in;
		transform: translateY(-28px);
	}

	90% {
		animation-timing-function: ease-out;
		transform: translateY(0);
	}

	95% {
		animation-timing-function: ease-in;
		transform: translateY(-8px);
	}

	100% {
		animation-timing-function: ease-out;
		transform: translateY(0);
	}
}